import { CONTRACTS_ENUM } from './../enums/contracts.enum';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';
import { Observable, Subscription } from 'rxjs';
import { LOGIN_STATE_ENUM } from '../enums/login-state.enum';
import { LoginResponseModel } from '../models/login-response.model';
import { IfStmt } from '@angular/compiler';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class ContractsGuard implements CanActivate {

    constructor(
        private router: Router,
        private sessionService_userInfo: SessionService,
        private toastService: ToastrService
    ) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve) => {
            let checked: boolean = false;
            let sub_sessionData: Subscription = this.sessionService_userInfo._permissions.subscribe((data:LoginResponseModel) => {
                if (!data || checked == true) {
                    return;
                }
                checked = true;
                let requiredPermissions:string[] = route.data.permissions;
             
                let {id,idContractType} = route.params;
                if(idContractType != undefined)
                    id = idContractType;
                let access = false;
                if(id == CONTRACTS_ENUM.LABORAL){
                    if(route.data.type == 'new' && data._usuario._tipoUsuario._laboralAlta){
                        access = true
                    } else if(route.data.type == 'edit' && data._usuario._tipoUsuario._laboralCambio){
                        access = true
                    }else if (route.data.type == 'control' && data._usuario._tipoUsuario._laboralVisualizar){
                        access = true
                    }
                }
                if(id == CONTRACTS_ENUM.GRADUACION){
                    if(route.data.type == 'new' && data._usuario._tipoUsuario._graduacionAlta){
                        access = true
                    } else if(route.data.type == 'edit' && data._usuario._tipoUsuario._graduacionCambio){
                        access = true
                    }else if (route.data.type == 'control' && data._usuario._tipoUsuario._graduacionVisualizar){
                        access = true
                    }
                }
                if(id == CONTRACTS_ENUM.BODA){
                    if(route.data.type == 'new' && data._usuario._tipoUsuario._bodaAlta){
                        access = true
                    } else if(route.data.type == 'edit' && data._usuario._tipoUsuario._bodaCambio){
                        access = true
                    }else if (route.data.type == 'control' && data._usuario._tipoUsuario._bodaVisualizar){
                        access = true
                    }
                }
                if(id == CONTRACTS_ENUM.ASOC_COMER){
                    if(route.data.type == 'new' && data._usuario._tipoUsuario._asociadoComerciaAlta){
                        access = true
                    } else if(route.data.type == 'edit' && data._usuario._tipoUsuario._asociadoComerciaCambio){
                        access = true
                    }else if (route.data.type == 'control' && data._usuario._tipoUsuario._asociadoComerciaVisualizar){
                        access = true
                    }
                }
                if(id == CONTRACTS_ENUM.EMPRESARIAL){
                    if(route.data.type == 'new' && data._usuario._tipoUsuario._empresarialAlta){
                        access = true
                    } else if(route.data.type == 'edit' && data._usuario._tipoUsuario._empresarialCambio){
                        access = true
                    }else if (route.data.type == 'control' && data._usuario._tipoUsuario._empresarialVisualizar){
                        access = true
                    }
                }
                if(id == CONTRACTS_ENUM.INSTITUCIONAL){
                    if(route.data.type == 'new' && data._usuario._tipoUsuario._institucionalAlta){
                        access = true
                    } else if(route.data.type == 'edit' && data._usuario._tipoUsuario._institucionalCambio){
                        access = true
                    }else if (route.data.type == 'control' && data._usuario._tipoUsuario._institucionalVisualizar){
                        access = true
                    }
                }

                if(id == CONTRACTS_ENUM.RENTA){
                    if(route.data.type == 'new' && data._usuario._tipoUsuario._rentaAlta){
                        access = true
                    } else if(route.data.type == 'edit' && data._usuario._tipoUsuario._rentaCambio){
                        access = true
                    }else if (route.data.type == 'control' && data._usuario._tipoUsuario._rentaVisualizar){
                        access = true
                    }
                }



            /*    if(CONTRACTS_ENUM.LABORAL == id && route.data.type == 'new' && data._usuario._tipoUsuario._laboralAlta){
                    access = true
                } else if(CONTRACTS_ENUM.LABORAL == id && route.data.type == 'edit' && data._usuario._tipoUsuario._laboralCambio){
                    access = true
                } else if(CONTRACTS_ENUM.LABORAL == id && route.data.type == 'control' && data._usuario._tipoUsuario._laboralVisualizar){
                    access = true
                }*/

                
                resolve(access);

                if(!access){
                    this.router.navigate(['/system/workspace/home']);
                    //
                    this.toastService.info('No tienes permiso');
                }
            })
            setTimeout(() => {
                if (sub_sessionData) {
                    sub_sessionData.unsubscribe();
                }
                if (checked == false) {
                    this.router.navigate(['/system/auth/login']);
                    resolve(false);
                }
            }, 10000);
        });
    }
}