import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { SessionService } from './session.service';
import { LOGIN_STATE_ENUM } from '../enums/login-state.enum';
import { LoginModel } from '../models/login.model';
import { APIS_ENUM } from '../enums/apis.enum';
import { LoginResponseModel } from '../models/login-response.model';
import { UserModel } from '../models/user.model';
import { RepresentativeModel } from '../models/representative.model';
import { ToastrService } from 'ngx-toastr';
import { DataDogService } from './datadog.service';

@Injectable()
export class AuthenticationService {

  constructor(
    private apiCall_restfull: ApiCallService,
    private sessionService_mySession: SessionService,
    private toastService: ToastrService,
    private dataDogService: DataDogService
  ) { }


  fnFormFirebaseToken(login: LoginModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.CHECKING);
  
      this.apiCall_restfull.fnPostPromise(login, APIS_ENUM.POST_FIREBASE)
        .then((res: LoginResponseModel) => {
          if(!res){
            this.sessionService_mySession.fnLogout();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
            return reject('Error de conexion con el servidor');
          }
          if (res._success) {
            this.sessionService_mySession.fnSaveSession(res);
            this.dataDogService.setUserSession(res._usuario._idUsuario.toString(), '', res._usuario._nombre + " " + res._usuario._apellidoPaterno + " " + res._usuario._apellidoMaterno);
            resolve();
          } else {
            this.sessionService_mySession.fnLogout();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.NO_LOGGED);
            reject(res._message);
          }
        })
        .catch(rej => {
          this.sessionService_mySession.fnLogout();
          this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
          reject('Error de conexion con el servidor');
        });
    });
  }

  fnFormRepresentante(login: LoginModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.CHECKING);
  
      this.apiCall_restfull.fnPostPromise(login, APIS_ENUM.POST_REPRESENTANTE)
        .then((res: LoginResponseModel) => {
          if(!res){
            this.sessionService_mySession.fnLogoutRepresentante();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
            return reject('Error de conexion con el servidor');
          }
          if (res._success) {
            this.sessionService_mySession.fnSaveSessionRepresentante(res);
            this.dataDogService.setUserSession(res._representante._idRepresentante.toString(), '', res._representante._nombre + " " + res._representante._apellidoPaterno + " " + res._representante._apellidoMaterno);
            resolve();
          } else {
            this.sessionService_mySession.fnLogoutRepresentante();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.NO_LOGGED);
            this.toastService.error(res._message);
            reject(res._message);
          }
        })
        .catch(rej => {
          this.sessionService_mySession.fnLogoutRepresentante();
          this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
          reject('Error de conexion con el servidor');
        });
    });
  }

  fnFormAsistente(login: LoginModel): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.CHECKING);
  
      this.apiCall_restfull.fnPostPromise(login, APIS_ENUM.POST_ASISTENTE)
        .then((res: LoginResponseModel) => {
          if(!res){
            this.sessionService_mySession.fnLogoutAsistente();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
            return reject('Error de conexion con el servidor');
          }
          if (res._success) {
            /*this.sessionService_mySession.fnSaveSessionAsistente(res);
            this.dataDogService.setUserSession(res._representante._idRepresentante.toString(), '', res._representante._nombre + " " + res._representante._apellidoPaterno + " " + res._representante._apellidoMaterno);
            resolve();*/
            resolve(res);
          } else {
            this.sessionService_mySession.fnLogoutAsistente();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.NO_LOGGED);
            this.toastService.error(res._message);
            reject(res._message);
          }
        })
        .catch(rej => {
          this.sessionService_mySession.fnLogoutAsistente();
          this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
          reject('Error de conexion con el servidor');
        });
    });
  }


  fnTokenLoginUser(str_sessionToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiCall_restfull.fnGetPromise([str_sessionToken], APIS_ENUM.POST_RECOVER_SESSION)
        .then((res: LoginResponseModel) => {
          if (res._success) {
            this.sessionService_mySession.fnSaveSession(res);
            this.dataDogService.setUserSession(res._usuario._idUsuario.toString(), '', res._usuario._nombre + " " + res._usuario._apellidoPaterno + " " + res._usuario._apellidoMaterno);
            resolve();
          } else {
            this.sessionService_mySession.fnLogout();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.EXPIRED_TOKEN);
            reject(res._message);
          }
        })
        .catch(rej => {
          this.sessionService_mySession.fnLogout();
          this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
          reject('Error de conexion con el servidor');
        });
    });
  }

  fnTokenLoginAsistente(str_sessionToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiCall_restfull.fnGetPromise([str_sessionToken], APIS_ENUM.POST_RECOVER_SESSION_ASISTENTE)
        .then((res: LoginResponseModel) => {
          if (res._success) {
            console.log(res);
            this.sessionService_mySession.fnSaveSessionAsistente(res);
            //this.dataDogService.setUserSession(res._representante._idRepresentante.toString(), '', res._representante._nombre + " " + res._representante._apellidoPaterno + " " + res._representante._apellidoMaterno);
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.LOGGED);
            resolve(res);
          } else if(!res._success) {
            this.sessionService_mySession.fnLogoutAsistente();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.EXPIRED_TOKEN);
            reject(res._message);
          }
        })
        .catch(rej => {
          this.sessionService_mySession.fnLogoutAsistente();
          this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
          reject('Error de conexion con el servidor');
        });
    });
  }

  fnTokenLoginRepresentante(str_sessionToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiCall_restfull.fnGetPromise([str_sessionToken], APIS_ENUM.POST_RECOVER_SESSION_REPRESENTATIVE)
        .then((res: LoginResponseModel) => {
          if (res._success) {
            this.sessionService_mySession.fnSaveSessionRepresentante(res);
            this.dataDogService.setUserSession(res._representante._idRepresentante.toString(), '', res._representante._nombre + " " + res._representante._apellidoPaterno + " " + res._representante._apellidoMaterno);
            resolve();
          } else {
            this.sessionService_mySession.fnLogoutRepresentante();
            this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.EXPIRED_TOKEN);
            reject(res._message);
          }
        })
        .catch(rej => {
          this.sessionService_mySession.fnLogoutRepresentante();
          this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.VALIDATION_ERROR);
          reject('Error de conexion con el servidor');
        });
    });
  }

  fnLogout(): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.sessionService_mySession.fnLogout();
        this.sessionService_mySession.fnLogoutRepresentante();
        this.sessionService_mySession.fnSetLoginStateValue(LOGIN_STATE_ENUM.LOGOUT);
        this.dataDogService.removeUserSession();
        resolve();
      }, 2000);
    });
  }


  
  
  fnSendResetPasswordMail(user_data:UserModel):Promise<any>{
    return new Promise((resolve,reject)=>{
      // POST_SEND_MAIL_RESET_PASSWORD
      this.apiCall_restfull.fnPostPromise(user_data,APIS_ENUM.POST_SEND_MAIL_RESET_PASSWORD)
    .then((res:LoginResponseModel)=>{
      if(res._success){
        resolve(res._message)
      }else{
        reject(res._message);
      }
    })
    .catch(rej=>{
      reject("Ups estamos teniendo problemas, intentalo mas tarde.")
    })
    });
    
  }

  fnSendResetPasswordAttendeesMail(representative_data:RepresentativeModel):Promise<any>{
    return new Promise((resolve,reject)=>{
      // POST_SEND_MAIL_RESET_PASSWORD
      this.apiCall_restfull.fnPostPromise(representative_data,APIS_ENUM.POST_SEND_MAIL_RESET_PASSWORD_ATTENDEES)
    .then((res:LoginResponseModel)=>{
      if(res._success){
        resolve(res._message)
      }else{
        reject(res._message);
      }
    })
    .catch(rej=>{
      reject("Ups... estamos teniendo problemas, intentalo más tarde.")
    })
    });
    
  }

  
  fnGetUserByResetPasswordToken(uuid:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.apiCall_restfull.fnGetPromise([uuid],APIS_ENUM.GET_USER_PASSWORD_BY_RESET_CLAVE)
      .then((res:UserModel)=>{
        if(res._idUsuario!=0){
          resolve(res);
        }else{
          reject("Ups estamos teniendo problemas, intentalo mas tarde");
        }
      })
      .catch(rej=>{
        reject("Ups estamos teniendo problemas, intentalo mas tarde");
      })
    });
  }
  
  fnGetRepresentativeByResetPasswordToken(uuid:string):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.apiCall_restfull.fnGetPromise([uuid],APIS_ENUM.GET_REPRESENTATIVE_PASSWORD_BY_RESET_CLAVE)
      .then((res:RepresentativeModel)=>{
        if(res._idRepresentante!=0){
          resolve(res);
          
        }else{
          reject("Ups estamos teniendo problemas, intentalo mas tarde");
        }
      })
      .catch(rej=>{
        reject("Ups estamos teniendo problemas, intentalo mas tarde");
      })
    });
  }

  fnSetNewPassword(user_data:UserModel):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.apiCall_restfull.fnPostPromise(user_data,APIS_ENUM.POST_SET_NEW_PASSWORD)
      .then((res:LoginResponseModel)=>{
        if(res._success){
          resolve(res._message);
        }else{
          reject(res._message);
        }
      })
      .catch(rej=>{
        reject("Ups estamos teniendo problemas, intentalo mas tarde");
      });
    });
  }

  fnSetNewPasswordAttendees(representative_data:RepresentativeModel):Promise<any>{
    return new Promise((resolve,reject)=>{
      this.apiCall_restfull.fnPostPromise(representative_data,APIS_ENUM.POST_SET_NEW_PASSWORD_ATTENDEES)
      .then((res:LoginResponseModel)=>{
        if(res._success){
          resolve(res._message);
        }else{
          reject(res._message);
        }
      })
      .catch(rej=>{
        reject("Ups estamos teniendo problemas, intentalo mas tarde");
      });
    });
  }


}
