import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataDogService } from './datadog.service';

@Injectable()
export class DataDogInterceptor implements HttpInterceptor {

    constructor(
        private dataDogService: DataDogService
    ){

    }
  // Lista blanca de dominios para interceptar
  private readonly whitelist = [environment.ip];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Verificar si la URL está en la lista blanca
    if (this.whitelist.some(domain => req.url.startsWith(domain))) {
      
      // Imprimir detalles de la solicitud
      /*console.log('Detalles de la solicitud:', {
        url: req.url,
        metodo: req.method,
        cuerpo: req.body
      });*/

      return next.handle(req)
        .pipe(
          tap(event => {
            // Intercepta y procesa respuestas exitosas
            if (event instanceof HttpResponse) {
              if(event.body._success == false){
                this.dataDogService.addError(
                    {
                      type: `HTTP ${req.method}`,
                      message:
                        'Resource'
                    },
                    {
                      error: event.body,
                      url: req.url,
                      body: req.body
                    }
                  );
              }
            }
          }),
          catchError((error: HttpErrorResponse) => {
            // Intercepta y procesa errores
            this.dataDogService.addError(
                {
                  type: `HTTP ${req.method}`,
                  message:
                    'Resource'
                },
                {
                  error,
                  url: req.url,
                  body: req.body
                }
              );
            return throwError(error); // Continúa la propagación del error
          })
        );
    } else {
      // Para URLs fuera de la lista blanca, permite que la petición continúe sin modificar
      return next.handle(req);
    }
  }
}