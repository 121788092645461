import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core'; 
import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from './system/guards/auth.guard'; 
import { SheetTutorialComponent } from './sheets/sheet-tutorial/sheet-tutorial.component';

export const routes: Routes = [
  { path: 'sheetTuto', component: SheetTutorialComponent},
  { path: '', redirectTo: 'system/auth', pathMatch: 'full' },
  { path: 'system/auth', loadChildren: './system/pages/no-authentication-pages/no-authentication-pages.module#NoAuthenticationPagesModule'},
  { 
    path: 'system/workspace',
    canActivate: [AuthGuard],
    loadChildren: './system/pages/authentication-pages/authentication-pages.module#AuthenticationPagesModule'
  },
  { 
    path: 'system/workspace-attendees',
    loadChildren: './system/pages/authentication-pages-attendees/authentication-pages-attendess.module#AuthenticationPagesAttendeesModule'
  },
  { 
    path: 'system/workspace-assistants',
    loadChildren: './system/pages/authentication-pages-assistants/authentication-pages-assistants.module#AuthenticationPagesAssistantsModule'
  },
  { path: 'pages', loadChildren: './pages/pages.module#PagesModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
  { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
  { path: '**', component: ErrorComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
   preloadingStrategy: PreloadAllModules,  // <- comment this line for enable lazy load
   onSameUrlNavigation: 'reload'
   // useHash: true
});