import { Injectable } from '@angular/core';
import { RepresentativeModel } from '../models/representative.model';
import { ABCModel } from './abc.model';
import { ApiCallService } from './api-call.service';
import { APIS_ENUM } from '../enums/apis.enum';
import { LoginModel } from '../models/login.model';

@Injectable({
  providedIn: 'root'
})
export class RepresentativeService extends ABCModel<RepresentativeModel>{

  constructor(
    protected apiCall: ApiCallService
  ) {
    super(apiCall);
    this.OBJ_KEY = '_idRepresentante';
    this.ARRAY_KEY = '_listaRepresentante';
    this.POST_CREATE = APIS_ENUM.POST_CREATE_REPRESENTATIVE;
    this.POST_EDIT = APIS_ENUM.POST_EDIT_REPRESENTATIVE;
    this.POST_DISABLE = APIS_ENUM.POST_DISABLE_REPRESENTATIVE;
  //  this.GET_BY_ID = APIS_ENUM.GET_REPRESENTATIVE_BY_ID;
   // this.GET_ALL = APIS_ENUM.
  }

  fnGetRepresentativesBySaleWebForm(login:LoginModel,idVenta: number){
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnPostWithParamsPromise(login,[idVenta], APIS_ENUM.GET_STUDENTS_BY_EVENT)
          .then(res => {
              this.$objArray.next(res[this.ARRAY_KEY]);
              resolve();
          })
          .catch(rej => {
              this.$objArray.next([]);
              reject();
          });
    });
  }

  fnGetRepresentativesByIdSale(id): Promise<any>{
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnGetPromise([id], APIS_ENUM.GET_REPRESENTATIVE_BY_IDSALE)
      .then((res) => {
        if(res[this.ARRAY_KEY].length > 0){
          resolve(res[this.ARRAY_KEY]);
        }else{
          resolve([]);
        }
      })
      .catch(() => {
        reject("Error de conexión");
      });
    });
  }

  fnCreateFromWebForm(obj) {
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnPostPromise(obj, APIS_ENUM.POST_CREATE_REPRESENT)
        .then(res => {
          if (res._success) {
            resolve(res._message)
          } else {
            reject(res._message);
          }
        })
        .catch(rej => {
          reject('Error de conexion');
        });
    });
  }

  fnShareSheetFromWebForm(obj) {
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnPostPromise(obj, APIS_ENUM.POST_SHARE_SHEET_REPRESENTANTE)
        .then(res => {
          if (res._success) {
            resolve(res._message)
          } else {
            reject(res._message);
          }
        })
        .catch(rej => {
          reject('Error de conexion');
        });
    });
  }

  fnGetAllBySale(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnGetPromise([id], APIS_ENUM.GET_REPRESENTATIVE_ALL)
        .then(res => {
          (res[this.ARRAY_KEY] as RepresentativeModel[]).forEach(data=>{
            data._nombreRepresentante = data._TipoRepresentante._nombre
          })
          this.$objArray.next(res[this.ARRAY_KEY]);
          resolve();
        })
        .catch(rej => {
          this.$objArray.next([]);
          reject();
        });
    });
  }
}
