import { BehaviorSubject, Observable } from 'rxjs';
import { ApiCallService } from './api-call.service';

export class ABCModel<T>{

    protected $objArray: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    _objArray: Observable<T[]> = this.$objArray.asObservable();

    protected $obj: BehaviorSubject<T> = new BehaviorSubject<T>(null);
    _obj: Observable<T> = this.$obj.asObservable();

    public GET_ALL: string;
    protected GET_BY_ID: string;
    protected POST_CREATE: string;
    protected POST_EDIT: string;
    protected POST_DISABLE: string;
    protected POST_ENABLE: string;
    protected GET_DELETE_ALL: string;

    protected ARRAY_KEY: string;
    protected OBJ_KEY: string;

    constructor(
        protected apiCallService_restfull: ApiCallService
    ) {

    }

    fnGetAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiCallService_restfull.fnGetPromise([], this.GET_ALL)
                .then(res => {
                    this.$objArray.next(res[this.ARRAY_KEY]);
                    resolve(); //check
                    //console.log(res);
                })
                .catch(rej => {
                    this.$objArray.next([]);
                    reject();
                });

        });
    }

    fnGetDeleteAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiCallService_restfull.fnGetPromise([], this.GET_DELETE_ALL)
                .then(res => {
                    this.$objArray.next(res[this.ARRAY_KEY]);
                    resolve();
                })
                .catch(rej => {
                    this.$objArray.next([]);
                    reject();
                });
        });
    }

    fnGetById(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiCallService_restfull.fnGetPromise([id], this.GET_BY_ID)
                .then(res => {
                    if (res[this.OBJ_KEY] != 0) {
                        this.$obj.next(res);
                        resolve();
                    } else {
                        this.$obj.next(null);
                        reject();
                    }
                })
                .catch(rej => {
                    this.$obj.next(null);
                    reject();
                });
        })
    }

    fnPostCreate(obj: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiCallService_restfull.fnPostPromise(obj, this.POST_CREATE)
                .then(res => {
                    if(res._success){
                        resolve(res)
                    }else{
                        reject(res._message);
                    }
                    
                })
                .catch(rej => {
                    reject('Error de conexion');
                });
        });
    }

    fnPostEdit(obj:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiCallService_restfull.fnPostPromise(obj,this.POST_EDIT)
            .then((res:any)=>{
                if(res._success){
                    resolve(res);
                }else{
                    reject(res._message);
                }
            })
            .catch(rej=>{

                reject('Error de conexion');
            })
        });
    }

    fnPostDisable(obj:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiCallService_restfull.fnPostPromise(obj,this.POST_DISABLE)
            .then((res:any)=>{
                if(res._success){
                    resolve(res._message);
                }else{
                    reject(res._message);
                }
            })
            .catch(rej=>{
                reject('Error de conexion');
            })
        });
    }

    fnPostEnable(obj:any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiCallService_restfull.fnPostPromise(obj,this.POST_ENABLE)
            .then((res:any)=>{
                if(res._success){
                    resolve(res._message);
                }else{
                    reject(res._message);
                }
            })
            .catch(rej=>{
                reject('Error de conexion');
            })
        });
    }

    fnRefreshObj():void{
        this.$obj.next(null);
    }

    fnRefreshObjArray():void{
        this.$objArray.next([]);
    }

    fnGetArray(){
        return this.$objArray.getValue() ? this.$objArray.getValue() : [];
    }

    fnGetObj(){
        return this.$obj.getValue();
    }
}