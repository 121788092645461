import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  private datadogScript: HTMLScriptElement;
  private datadogRum: any;
  private loaded = false;
  private readonly renderer: Renderer2;
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private readonly _renderer: RendererFactory2,
    private router: Router
  ) {
    this.renderer = _renderer.createRenderer(null, null);
  }

  config = {
    applicationId: 'a4f9d3b1-9205-41b9-9702-857fb87e3159',
    clientToken: 'pub4203f7dbf286bddb1485e0256921ce4b',
    site: 'datadoghq.com',
    service: 'eventlink',
    env: environment.datadogEnv,
    version: '0.0.0',
    sampleRate: 100,
    replaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  };

  initScriptDataDog() {
    return new Promise((res) => {
      if (!this.loaded) {
        this.datadogScript = this.renderer.createElement('script');
        this.datadogScript.type = 'text/javascript';
        this.datadogScript.defer = true;
        this.datadogScript.src =
          'https://www.datadoghq-browser-agent.com/datadog-rum-v4.js';

        this.datadogScript.onload = () => {
            this.loaded = true;
            this.datadogRum = window['DD_RUM'];
            this.datadogRum.init(this.config);
            //this.datadogRum.startSessionReplayRecording();
            res(true);
        };
        this.datadogScript.onerror = () => {
          res(false);
        };
        this.renderer.appendChild(this._document.body, this.datadogScript);
      } else {
        res(false);
      }
    });
  }

  addEvent({
    action,
    description,
    page
  }: {
    action: string;
    description: string;
    page?: string;
  }) {
    try {
      if (!this.datadogRum) {
        return;
      }
      this.datadogRum.addAction(action, {
        page: page || this.router.url,
        description: this.loggedUserTxt() + description
      });
    } catch (error) {
      console.log(error);
    }
  }

  addError(
    error: {
      source?: string;
      message?: string;
      stack?: string;
      type: string;
    },
    context = {}
  ) {
    try {
      if (!this.datadogRum) {
        return;
      }
      this.datadogRum.addError(
        {
          source: error.source || '',
          message: this.loggedUserTxt() + (error.message || ''),
          stack: error.stack || '',
          type: error.type || ''
        },
        context
      );
    } catch (error) {
      console.error(error);
    }
  }

  private loggedUserTxt() {
    try {
      if (!this.datadogRum) {
        return '[NO_USER_DATA] ';
      }
      const user = this.datadogRum.getUser();
      if (user && user.id) {
        return `[USER_ID:${user.id}] [USER_USERNAME:${user.username}] [USER_EMAIL:${user.email}] `;
      }
      return '[NO_USER_DATA] ';
    } catch (error) {
      console.error(error);
      return '[NO_USER_DATA] ';
    }
  }

  setUserSession(id: string, email: string, username: string) {
    try {
      if (!this.datadogRum) {
        return;
      }
      this.datadogRum.setUser({
        id: id,
        name: username,
        email
      });
    } catch (error) {
      console.log(error);
    }
  }

  removeUserSession() {
    try {
      if (!this.datadogRum) {
        return;
      }
      this.datadogRum.removeUser();
    } catch (error) {
      console.log(error);
    }
  }
}
