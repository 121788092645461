import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationModel } from '../models/notification.model';
import { ApiCallService } from './api-call.service';
import { AlertModel } from '../models/alert.model';
import { APIS_ENUM } from '../enums/apis.enum';
import { ResponseModel } from '../models/reponse.model';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  $notificaciones: BehaviorSubject<NotificationModel[]> = new BehaviorSubject<NotificationModel[]>([]);
  _notificaciones: Observable<NotificationModel[]> = this.$notificaciones.asObservable();

  constructor(
    protected apiCallService_restfull: ApiCallService
  ) { }



  fnSetNotification(notification: NotificationModel) {
    let aux: NotificationModel[] = this.$notificaciones.getValue();
    aux.push(notification);
    this.$notificaciones.next(aux);
  
  }


  private $AlertArray_recoveryNotification: BehaviorSubject<AlertModel[]> = new BehaviorSubject<AlertModel[]>([]);
  _AlertArray_recoveryNotification: Observable<AlertModel[]> = this.$AlertArray_recoveryNotification.asObservable();

  fnGetLastTracing(): Promise<any> {//se llama a las alertas
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnGetPromise([], APIS_ENUM.GET_ALERTS_SESSION)
        .then((res) => {
          this.$AlertArray_recoveryNotification.next(res._listaAlerta);
          resolve({});
        })
        .catch((rej) => {
          reject("Error de conexion con el servidor");
        })
    })
  }

  fnCheckNotifications(){
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnGetPromise([], APIS_ENUM.GET_CHECK_NOTIFICATIONS)
      .then((res: ResponseModel) => {
        if(res._success){
          resolve({});
        }else{
          reject(res._message);
        }
      })
      .catch((rej) => {
        reject("Error de conexión");
      });
    });
  }

  fnCheckAlert(idAlerta: number){
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnGetPromise([idAlerta], APIS_ENUM.GET_CHECK_ALERT)
      .then((res: ResponseModel) => {
        if(res._success){
          resolve({});
        }else{
          reject(res._message);
        }
      })
      .catch((rej) => {
        reject("Error de conexión");
      });
    });
  }

  fnHideAlert(idAlerta: number){
    return new Promise((resolve, reject) => {
      this.apiCallService_restfull.fnGetPromise([idAlerta], APIS_ENUM.GET_HIDE_ALERT)
      .then((res: ResponseModel) => {
        if(res._success){
          resolve(res._message);
        }else{
          reject(res._message);
        }
      })
      .catch((rej) => {
        reject("Error de conexión");
      });
    });
  }

}
