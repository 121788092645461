export enum APIS_ENUM {

    //PLACE
    GET_PLACE_ALL = '/api/datosLugarEvento',
    POST_CREATE_PLACE = '/api/datosLugarEvento/crear',
    POST_EDIT_PLACE = '/api/datosLugarEvento/editar',
    GET_PLACE_BY_ID = '/api/datosLugarEvento/idDatosLugarEvento/?',
    GET_PLACE_DELETE_ALL = '/api/datosLugarEvento/eliminados',
    POST_DISABLE_PLACE = '/api/datosLugarEvento/eliminar',
    POST_ENABLE_PLACE = '/api/datosLugarEvento/activar',
    POST_UPLOAD_EXCEL_PLACE = '/api/datosLugarEvento/altaMasiva',

    //PAYMENT-VOUCHERS 
    GET_PAYMENT_VOUCHER_ALL = '/api/pagos/ventaComprobante',
    POST_CREATE_PAYMENT_VOUCHER = '/',
    POST_EDIT_PAYMENT_VOUCHER = '/',
    GET_PAYMENT_VOUCHER_BY_ID = '/api/pago/pagoVentaComprobante/?',
    GET_PAYMENT_VOUCHER_DELETE_ALL = '/api/pagos/ventaComprobanteCancelados',
    POST_DISABLE_PAYMENT_VOUCHER = '/api/pagos/cancelar',
    POST_ENABLE_PAYMENT_VOUCHER = '/api/pagos/recuperar',
    POST_ACCEPT_PAYMENT_VOUCHER = '/api/pagos/aceptar',
    GET_PAYMENT_VOUCHER_ACCEPT_ALL = '/api/pagos/ventaComprobanteAceptados',
    GET_PAYMENT_VOUCHER_ATTENDEES_ALL = '/api/pagos/ventaComprobanteRepresentantes',
    GET_PAYMENT_VOUCHER_BY_ATTENDEES_BY_ID = '/api/pagos/comprobanteRepresentante/?',

    //Seats
    GET_SEATS_REPRESENTANT = '/api/sillas/?/alunmo/?', //idVenta, idAlumno
    POST_SEATS_MODIFY_NAME = '/api/sillas/editar', //seatDto
    POST_SEATS_TICKETS = '/api/mesas/generarBoletosAlumnoDescarga',
    POST_SEATS_ONE_TICKET = '/api/mesas/generarBoletosAlumnoDescargaUnBoleto/?',
    DESCARGA_SEATS_TICKETS = '/imagen/?/token/?',


    //PLACE TYPE
    GET_PLACE_TYPE_ALL = '/api/tiposLugarEvento',

    //TABLE SERVICE
    POST_UPLOAD_EXCEL_TABLE = '/api/mesas/crearExcelMesas/?/correo/?',
    POST_UPLOAD_EXCEL_TABLE_EXTRA = '/api/mesas/altaMasiva/agregarMesas/?/correo/?',
    GET_TABLES_BY_SALE = '/api/mesasVenta/?',
    POST_DELETE_TABLE = '/api/mesas/eliminar',
    POST_SEND_TICKETS = '/api/mesas/generarBoletosAlumno/?',
    POST_SEND_TICKETS_TEST = '/api/representantes/sendCorreoPrueba',

    //REPRESENTATIVETYPE SERVICE
    GET_REPRESENTATIVETYPE_ALL = '/api/tiposRepresentante',
    POST_REPRESENTATIVE_TYPE_CREATE = '/api/tiposRepresentante/crear',
    POST_REPRESENTATIVE_TYPE_EDIT= '/api/tiposRepresentante/editar',
    POST_REPRESENTATIVE_TYPE_DISABLE = '/api/tiposRepresentante/eliminar',
    GET_REPRESENTATIVE_TYPE_BY_ID = '/api/tiposRepresentante/editar',
    GET_REPRESENTATIVE_TYPE_ALL_DELETE= '/api/tiposRepresentante/eliminados',
    POST_REPRESENTATIVE_TYPE_ENABLE = '/api/tiposRepresentante/reactivar',

    //REPRESENTATIVE SERVICE
    GET_REPRESENTATIVE_ALL = '/api/representantes/venta/?',
    POST_CREATE_REPRESENTATIVE = '/api/representantes/crear',
    POST_EDIT_REPRESENTATIVE = '/api/representantes/editar',
    POST_SHARE_SHEET_REPRESENTANTE = '/api/representantes/compartir',
    POST_DISABLE_REPRESENTATIVE = '/api/representantes/eliminar',
    GET_REPRESENTATIVE_BY_IDSALE = '/api/representantes/evento/?/alumnos/sesion',

    //REPRESENTATIVE CLIENT SERVICE
    POST_CREATE_REPRESENTATIVE_CLIENT = '/api/representantes/cliente/crear',
    POST_EDIT_REPRESENTATIVE_CLIENT = '/api/representantes/cliente/editar',
    POST_DISABLE_REPRESENTATIVE_CLIENT = '/api/representantes/cliente/eliminar',
    GET_REPRESENTATIVE_BY_IDCLIENT = '/api/representantes/cliente/idCliente/?',

    //EVENTDATA SERVICE
    GET_EVENTDATA_BY_IDSALE = '/api/datosEventos/venta/?',
    GET_CUSTOM_FIELD_BY_ID = '/api/datosEventos/customField/idVenta/?',
    POST_UPDATE_EVENTDATA_BY_ID = '/api/datosEventos/editar',
    POST_UPDATE_CUSTOMFIELD_BY_ID = '/api/datosEventos/camposPersonalizados/editar',
    POST_CREATE_EVENTDATA = '/api/datosEventos/crear',
    POST_SIGN_CONTRACT = '/api/datosEventos/firmar',
    POST_DELETE_SIGN_CONTRACT = '/api/datosEventos/quitarFirma',
    POST_SALE_TOTALS = '/api/ventas/totalEventoYPagado/?',
    POST_ENABLE_FORM_DATA = '/api/datosEventos/bloquearDatos/editar',

    //SELL TYPE
    GET_ALL_SELL_TYPE = '/api/ventas/tipoVenta',

    // GET_CUSTUMER_CUSTOM_FIELD
    GET_CUSTUMER_CUSTOM_FIELD = '/api/CampoPersonalizadoCliente/lista/idCliente/?',
    POST_CUSTUMER_CUSTOM_FIELD_EDIT = '/api/CampoPersonalizadoCliente/editar',

    //SALEANNEXED SERVICE
    POST_SALEANNEXED_CREATE = '/api/anexosVenta/crear',
    GET_SALEANNEXED_BY_SALE = '/api/anexosVenta/venta/?',
    POST_SALEANNEXED_UPLOAD_DOCUMMENT = '/api/anexosVenta/documentos/crear/?',

    //SALEPAYMENT SERVICE
    POST_SALEPAYMENT_CREATE = '/api/pagos/crear',
    GET_SALEPAYMENT_BY_SALE = '/api/pagos/venta/?',
    POST_SALEPAYMENT_UPLOAD_FACT = '/api/pagos/factura/editar/?',
    POST_SALEPAYMENT_UPLOAD_CMP = '/api/pagos/editar/?',
    POST_SALEPAYMENT_CREATE_BY_ATTENDEES = '/api/pagos/crearPorRepresentante',
    POST_SALEPAYMENT_UPLOAD_CMP_BY_ATTENDEES = '/api/pagos/editarPorRepresentante/?',
    GET_PAYMENT_METHODS = '/api/formaPago',

    // AUTH SERVICE
    POST_LOGIN = '/api/login/user',
    POST_RECOVER_SESSION = '/api/login/datosSesion/?',
    POST_RECOVER_SESSION_ASISTENTE = '/api/login/datosSesionAsistente/?',
    POST_RECOVER_SESSION_REPRESENTATIVE = '/api/login/datosSesionRepresentante/?',
    POST_FIREBASE = '/api/login/firebase',
    POST_REPRESENTANTE = '/api/login/representante',
    POST_ASISTENTE = '/api/login/asistente',
    POST_SEND_TOKEN_NOTIFICATION = '/api/login/changeTokenNotificacion',

    //AUTHENTICATION AND RESET PASSWORD
    POST_SEND_MAIL_RESET_PASSWORD = '/api/usuarios/pasword/olvidado',
    POST_SEND_MAIL_RESET_PASSWORD_ATTENDEES = '/api/representantes/password/olvidado',
    GET_USER_PASSWORD_BY_RESET_CLAVE = '/api/usuarios/claveCambio/?',
    GET_REPRESENTATIVE_PASSWORD_BY_RESET_CLAVE = '/api/representantes/claveCambio/?',
    POST_SET_NEW_PASSWORD = '/api/usuarios/pasword/nuevo',
    POST_SET_NEW_PASSWORD_ATTENDEES = '/api/representantes/password/nuevo',


    // USER SERVICE
    GET_USER_ALL = '/api/usuarios',
    GET_USER_BY_ID = '/api/usuarios/idUsuario/?',
    POST_USER_CREATE = '/api/usuarios/crear',
    POST_USER_EDIT = '/api/usuarios/editar',
    POST_USER_EDIT_ICON = '/api/usuarios/icono/editar/?',
    POST_USER_DISABLE = '/api/usuarios/eliminar/?',
    POST_USER_ENABLE = '/api/usuarios/reactivar',
    GET_USER_ALL_DELETE = '/api/usuarios/eliminados',
    GET_ASSISTANCE_USERS_BY_SALE = '/api/ventas/usuarios/asistencia/?',
    POST_SAVE_ASSISTANCE = '/api/ventas/usuarios/asistencia/crear/?',
    POST_USER_UPDATE_IN_SALE = '/api/ventas/editar/usuario',

    //NOTES
    POST_NOTE_CREATE = '/api/ventas/notas/crear',
    POST_NOTE_DELETE = '/api/ventas/notas/ocultar', 
    POST_NOTE_RESTORE = '/api/ventas/notas/recuperar',
    POST_NOTE_EDIT = '/api/ventas/notas/editar',
    GET_NOTES_VENTA = '/api/ventas/notas/venta/?', 
    GET_NOTES_OPORTUNIDAD = '/api/ventas/notas/oportunidad/?', 

    // REPORTS
    POST_REPORTS_FOLLOWS = '/api/reportes/seguimientos',
    POST_REPORTS_ACTIVITIES = '/api/reportes/tareasVenta',
    POST_GENERATE_ACTIVITIES_EXCEL = '/api/reportes/tareasVenta/generarExcel',
    POST_GENERATE_FOLLOW_EXCEL = '/api/reportes/seguimientos/generarExcel',

    
    //USER TYPE SERVICE
    GET_USER_TYPE_ALL = '/api/tiposUsuarios',
    GET_USER_TYPE_BY_ID = '/api/tiposUsuarios/idTipoUsuario/?',
    POST_USER_TYPE_CREATE = '/api/tiposUsuarios/crear',
    POST_USER_TYPE_EDIT = '/api/tiposUsuarios/editar',
    POST_USER_TYPE_DISABLE = '/api/tiposUsuarios/eliminar',
    POST_USER_TYPE_ENABLE = '/api/tiposUsuarios/reactivar',
    GET_USER_TYPE_ALL_DELETE = '/api/tiposUsuarios/eliminados',

    //INSTITUTION TYPE
    GET_INSTITUTION_TYPE_ALL = '/api/tiposInstituciones',
    GET_INSTITUTION_TYPE_BY_ID = '/api/tiposInstituciones/idTipoInstitucion/?',
    POST_INSTITUTION_TYPE_CREATE = '/api/tiposInstituciones/crear',
    POST_INSTITUTION_TYPE_EDIT = '/api/tiposInstituciones/editar',
    POST_INSTITUTION_TYPE_DISABLE = '/api/tiposInstituciones/eliminar',
    POST_INSTITUTION_TYPE_ENABLE = '/api/tiposInstituciones/reactivar',
    GET_INSTITUTION_TYPE_ALL_DELETE = '/api/tiposInstituciones/eliminadas', 
 

    //INSTITUTION
    GET_INSTITUTION_ALL = '/api/instituciones',
    GET_INSTITUTION_BY_ID = '/api/instituciones/idInstitucion/?',
    POST_INSTITUTION_CREATE = '/api/instituciones/crear',
    POST_INSTITUTION_EDIT = '/api/instituciones/editar',
    POST_INSTITUTION_DISABLE = '/api/instituciones/eliminar',
    POST_INSTITUTION_ENABLE = '/api/instituciones/reactivar',
    GET_INSTITUTION_ALL_DELETE = '/api/instituciones/eliminadas',
    POST_UPLOAD_EXCEL_INSTITUTION = '/api/instituciones/altaMasiva',

    //LOCATIONS
    GET_STATES_ALL = '/api/estados',
    GET_MUNICIPALITY_BY_ID_STATE = '/api/municipios/?',


    //CLIENT
    GET_CLIENT_ALL = '/api/clientes',
    GET_CLIENT_ALL_PROSPECT = '/api/clientesProspectos',
    GET_CLIENT_BY_ID = '/api/clientes/idCliente/?',
    POST_CLIENT_CREATE = '/api/clientes/crear',
    POST_CLIENT_EDIT = '/api/clientes/editar',
    POST_CLIENT_EDIT_DIRECTION = '/api/clientes/editar/direccion',
    POST_CLIENT_EDIT_FACTURATION = '/api/clientes/editar/facturacion',
    POST_CLIENT_DISABLE = '/api/clientes/eliminar',
    POST_CLIENT_ENABLE = '/api/clientes/reactivar',
    GET_CLIENT_ALL_DELETE = '/api/clientes/eliminados',
    GET_CLIENT_ALL_DELETE_PROSPECT = '/api/clientesProspectos/eliminados',
    GET_GENERATE_EXCEL = '/excel/plantillaAltaClientes',
    GET_GENERATE_EXCEL_REPORT = '/excel/plantillaReporteClientes',
    GET_GENERATE_EXCEL_REPORT_PROSPECT = '/excel/plantillaReporteClientesProspecto',
    POST_CLIENT_EXCEL = '/api/clientes/altaMasiva',
    POST_DELETE_MASIVE_CLIENT = '/api/clientes/eliminar/masivo',
    POST_ENABLE_MASIVE_CLIENT = '/api/clientes/reactivar/masiva',

    //PROVIDER
    GET_PROVIDER_ALL = '/api/proveedores',
    GET_PROVIDER_BY_ID = '/api/proveedores/idProveedor/?',
    POST_PROVIDER_CREATE = '/api/proveedores/crear',
    POST_PROVIDER_EDIT = '/api/proveedores/editar',
    POST_PROVIDER_DISABLE = '/api/proveedores/eliminar',
    POST_PROVIDER_ENABLE = '/api/proveedores/reactivar',
    GET_PROVIDER_ALL_DELETE = '/api/proveedores/eliminados',
    POST_CREATE_FILE_PROVIDER = '/api/proveedores/urlContrato/?',
    POST_DELETE_FILE_PROVIDER = '/api/proveedores/urlContrato/eliminar/?',
    POST_WEBFORM_CREATE = '/api/clientes/formularioWeb/crear',
    POST_UPLOAD_EXCEL_PROVIDER = '/api/proveedores/altaMasiva',

    //PROVIDER TYPE
    GET_PROVIDER_TYPE_ALL = '/api/tiposProveedor',
    GET_PROVIDER_TYPE_BY_ID = '/api/tiposProveedor/idTipoProveedor/?',
    POST_PROVIDER_TYPE_CREATE = '/api/tiposProveedor/crear',
    POST_PROVIDER_TYPE_EDIT = '/api/tiposProveedor/editar',
    POST_PROVIDER_TYPE_DISABLE = '/api/tiposProveedor/eliminar',
    POST_PROVIDER_TYPE_ENABLE = '/api/tiposProveedor/reactivar',
    GET_PROVIDER_TYPE_ALL_DELETE = '/api/tiposProveedor/eliminados',



    //PRODUCT CATEGORY
    GET_PRODUCT_CATEGORY_ALL = '/api/categoriaproducto',
    GET_PRODUCT_CATEGORY_BY_ID = '/api/categoriaproducto/idCategoriaProducto/?',
    POST_PRODUCT_CATEGORY_CREATE = '/api/categoriaproducto/crear',
    POST_PRODUCT_CATEGORY_EDIT = '/api/categoriaproducto/editar',
    POST_PRODUCT_CATEGORY_DISABLE = '/api/categoriaproducto/eliminar',
    POST_PRODUCT_CATEGORY_ENABLE = '/api/categoriaproducto/reactivar',
    GET_PRODUCT_CATEGORY_ALL_DELETE = '/api/categoriaproducto/eliminados',
    GET_PRODUCT_CATEGORY_ALL_CHILD = '/api/categoriaproducto/idCategoriaProducto/?',
    POST_PRODUCT_CATEGORY_PRIORITY = "/api/categoriaproducto/editarPrioridad",

    //PRODUCT 
    GET_PRODUCT_ALL = '/api/productos',
    GET_PRODUCT_ALL_WITHOUT_CHILD = '/api/productos/sinHijos',
    GET_PRODUCT_BY_ID = '/api/productos/idProducto/?',
    POST_PRODUCT_CREATE = '/api/productos/crear',
    POST_PRODUCT_EDIT = '/api/productos/editar',
    POST_PRODUCT_DISABLE = '/api/productos/eliminar',
    POST_PRODUCT_ENABLE = '/api/productos/reactivar',
    GET_PRODUCT_ALL_DELETE = '/api/productos/eliminados',
    POST_CREATE_FILES_PRODUCT = '/api/productos/imagenes/idProducto/?',
    POST_DELETE_IMG = '/api/productos/imagenes/eliminar',
    GET_PRODUCT_BY_PRODUCT_CATEGORY = '/api/productos/idCategoriaProducto/?',
    POST_UPLOAD_EXCEL_PRODUCT= '/api/productos/altaMasiva',
    POST_PRODUCT_PRIORITY = '/api/productos/prioridad',

    //SHOPPING
    GET_SHOPPING_ALL = '/api/compras',
    GET_SHOPPING_BY_ID = '/api/compras/idCompra/?',
    POST_SHOPPING_CREATE = '/api/compras/crear',
    POST_SHOPPING_EDIT = '/api/compras/editar',
    POST_SHOPPING_DISABLE = '/api/compras/eliminar',
    POST_SHOPPING_ENABLE = '/api/compras/reactivar',
    GET_SHOPPING_ALL_DELETE = '/api/compras/eliminadas',
    GET_STATUS_SHOPPING = "/api/statusCompra",
    GET_BY_STATUS_SHOPPING = '/api/compras/idStatusCompra/?',
    POST_SHOPPING_TO_WAREHOUSE = '/api/Compra/partidaCompra/almacen/editar',
    POST_SHOPPING_TO_WAREHOUSE_DESVINCULATE = '/api/Compra/partidaCompra/almacen/desvincular',
    POST_SHOPPING_OUT_OF_WAREHOUSE = '/api/Compra/partidaCompra/almacen/aunNoLLega',


    //Payment pago compras
    GET_PAYMENT_ALL = '/api/pagocompras',
    POST_PAYMENT_CREATE = '/api/pagocompras/crear',
    POST_PAYMENT_DISABLE = '/api/pagocompras/eliminar',
    GET_PAYMENT_ALL_DELETE = '/api/pagocompras/eliminados',
    GET_PAYMENTS_BY_ID_SHOPPING = '/api/pagocompras/idCompra/?',
    POST_SUBMIT_FILES_PAYMENTS = '/api/pagocompras/urlTicket/?',


    //CROQUIS
    GET_CROQUIS_ALL = '/api/croquis',
    GET_CROQUIS_ALL_DELETE = '/api/croquis/eliminados',
    GET_CROQUIS_BY_ID = '/api/croquis/idCroquis/?',
    POST_CROQUIS_CREATE = '/api/croquis/crear',
    POST_CROQUIS_EDIT = '/api/croquis/editar',
    POST_CROQUIS_DISABLE = '/api/croquis/eliminar',
    POST_CROQUIS_ENABLE = '/api/croquis/reactivar',
    POST_CROQUIS_UPLOAD_IMAGE = '/api/croquis/subir/?',


    //EVENT TYPES
    GET_EVENT_TYPES_ALL = "/api/tipoevento/activos",
    GET_EVENT_TYPES_BY_ID = "/api/tipoevento/idTipoEvento/?",
    POST_EVENT_TYPES_CREATE = "/api/tipoevento/crear",
    POST_EVENT_TYPES_EDIT = "/api/tipoevento/editar",
    POST_EVENT_TYPES_PRIORITY = "/api/tipoevento/editarPrioridad",
    POST_EVENT_TYPES_ENABLE = "/api/tipoevento/reactivar",
    POST_EVENT_TYPES_DISABLE = "/api/tipoevento/eliminar",
    GET_EVENT_TYPES_ALL_DELETE = "/api/tipoevento/eliminados",
    POST_EVENT_TYPES_UPLOAD_DOCS = "/api/tipoevento/subir/?",
    


    //Package ahora propuestas
    GET_PACKAGE_ALL = '/api/paquetes',
    GET_PACKAGE_BY_ID = '/api/paquetes/idPaquete/?',
    POST_PACKAGE_CREATE = '/api/paquetes/crear',
    POST_PACKAGE_EDIT = '/api/paquetes/editar',
    POST_PACKAGE_DISABLE = '/api/paquetes/eliminar',
    POST_PACKAGE_ENABLE = '/api/paquetes/reactivar',
    GET_PACKAGE_DELETE = '/api/paquetes/eliminados',
    POST_PACKAGE_DELETE_PDF = '/api/paquetes/pdf/eliminar/?',
    POST_PACKAGE_EDIT_PDF = '/api/paquetes/pdf/editar/?',


    //opporunity
    GET_OPPORTUNITIES_BY_ID_CLIENT = '/api/oportunidades/idCliente/?',
    POST_OPPORTUNIY_CREATE = '/api/oportunidades/crear',
    GET_INFO_REQUEST_BYOPPORTUNITY = '/api/datosSolicitud/idOportunidad/?',

    //tracing (seguimiento)

    GET_TRACING_BY_ID_OPPORTUNITY = '/api/seguimiento/idOportunidad/?',
    POST_TRACING_CREATE = '/api/seguimiento/crear',
    GET_ALL_TYPE_TRACING_CATEGORY = '/api/categoriaTipoSeguimiento', //-->Regresar las categoriasTipoSeguimiento, lleva dentro los tipos seguimiento en un array
    GET_ALERT = '/api/alertas/pushNotificationApi',
    GET_ALERT_BY_OPPORTUNITIES = '/api/seguimiento/ultimoSeguimiento',
    GET_ALERTS_SESSION = '/api/alertas/sesion',
    GET_ALERTS_SESSION_ADMINS = '/api/alertas/panel',
    GET_CHECK_NOTIFICATIONS = '/api/alertas/editarVista/sesion',
    GET_CHECK_ALERT ='/api/alertas/?/editarVista',
    GET_HIDE_ALERT ='/api/alertas/?/ocultarAlerta',
    GET_TRACING_RETURN_CALLS_DATES = '/api/seguimiento/mes/?/year/?',


    //type event
    GET_ALL_TYPE_EVENTS = '/api/TipoEvento',

    //provenance place
    GET_ALL_PROVENANCE_PLACE = '/api/lugarProveniencia',

    //linea de negocio
    GET_ALL_BUSINESS_LINE = '/api/lista/lineaDeNegocio',
    GET_ALL_BUSINESS_LINE_BY_TYPE = '/api/lista/lineaDeNegocio/idTipoLineaDeNegocio/?',
    GET_ALL_BUSINESS_LINE_TYPE = '/api/lista/TipoLineaDeNegocio',

    //cotizaciones
    GET_QUOTES_BY_ID_OPPORTUNITY = '/api/ventas/idOportunidad/?',
    GET_QUOTATION_BY_ID = '/api/ventas/idVenta/?',
    GET_TOTAL_QUOTATION = '/api/ventas/totalCotizacion/?',
    GET_VIEW_QUOTATION = '/api/ventas/vista/idVenta/?',
    POST_PDF_BY_QUOTATION = '/api/ventas/PDF/idSeguimiento/?/?',
    POST_PDF_BY_SALE = '/api/ventas/PDF/idVenta/?',
    POST_PDF_SEND_BY_SALE = '/api/ventas/PDF/send/?/idVenta/?',
    POST_PDF_SEND_EMAIL_BY_SALE = '/api/ventas/PDF/enviarEmail/idVenta/?/mensaje/?',
    POST_PDF_SEND_WHATSAPP_BY_SALE = '/api/ventas/PDF/enviarWhatsApp/idVenta/?/mensaje/?',
    GET_PDF_DOCUMENT = '/PDFs/ReciboContrato/descarga/correo/?/codigo/?',


    //SEGUIMIENTOS
    GET_REQUEST_BY_WEB_FORM = '/api/oportunidades/formularioWeb',

    //SALES
    GET_SALES_INFO = '/api/ventas/usuarios',
    GET_SALES_INFO_ALL = '/api/ventas/todos/usuarios',
    GET_SALES_ASSISTANTS_DATES = '/api/ventas/asistentes/fechas/?',
    GET_ALL_PRODUCTION = '/api/ventas/usuariosProduccion',
    GET_ALL_RAZON_SALIDA = '/api/ventas/partidaVenta/razonSalida',
    POST_VALIDATE_SALE_ITEMS = '/api/ventas/partidasVenta/validar',
    POST_CHANGE_ASSISTANTS_DATES = '/api/ventas/asistentes/fechas/cambiar',
    POST_ASSIGN_SALE_TO_WAREHOUSE = '/api/ventas/partidaVenta/almacen/editar',
    POST_DELETE_SALE_TO_WAREHOUSE = '/api/ventas/partidaVenta/almacen/quitar',
    GET_SHOPPING_BY_ID_SALE ='/api/compras/idVenta/?',

    // SALE CUT-OFF DATE
    GET_SALES_CUT_DATE_BY_SALE = '/api/fechaCorteVenta/venta/?',
    POST_CREATE_SALE_CUT_DATE = '/api/fechaCorteVenta/crear',
    POST_EDIT_SALE_CUT_DATE = '/api/fechaCorteVenta/editar',
    POST_DELETE_SALE_CUT_DATE = '/api/fechaCorteVenta/cancelar',

    //ANNEXED TYPES
    GET_ANNEXED_TYPE_ALL = '/api/tiposAnexosVenta',
    GET_ANNEXED_TYPE_BY_ID = '/api/tiposAnexosVenta/idTipoAnexoVenta/?',
    POST_ANNEXED_TYPE_CREATE = '/api/tiposAnexosVenta/nuevo',
    POST_ANNEXED_TYPE_EDIT = '/api/tiposAnexosVenta/editar',
    POST_ANNEXED_TYPE_DISABLE = '/api/tiposAnexosVenta/eliminar',
    POST_ANNEXED_TYPE_ENABLE = '/api/tiposAnexosVenta/activar',
    GET_ANNEXED_TYPE_ALL_DELETE = '/api/tiposAnexosVenta/eliminados',


    //QUOTATION FORM
    POST_CREATE_REPRESENT ='/api/representantes/evento/alumnos/crear',

    GET_STUDENTS_BY_EVENT ='/api/representantes/evento/?/alumnos',
    POST_EVENT_WEB_CREATE ='/api/clientes/eventos/formularioWeb/crear',
    GET_PACKAGES_FORM ='/api/paquetes/formulario',


    //TASK-SELL
    POST_CREATE_TASK_SELL = '/api/tareaVentas/crear',
    POST_EDIT_TASK_SELL = '/api/tareaVentas/editar',
    POST_DISABLE_TASK_SELL = '/api/tareaVentas/eliminar',
    POST_ENABLE_TASK_SELL = '/api/tareaVentas/reactivar',
    GET_ENABLE_TASK_SELL = '/api/tareaVentas',
    GET_DISABLE_TASK_SELL = '/api/tareaVentas/eliminadas',
    GET_TASK_SELL_BY_ID = '/api/tareaVentas/idTareaVenta/?',
    GET_TASK_SELL_BY_ID_SALE = '/api/tareaVentas/venta/?',
    GET_TAKS_SELL_BY_MONTH_YEAR = '/api/tareasVenta/mes/?/year/?',
    GET_PARTIDAVENTA_BY_ID = '/api/ventas/partidasVenta/idPartidaVenta/?',
    GET_TASK_SELL_BY_IDPARTIDAVENTA = '/api/ventas/tareasVenta/partidasVenta/idPartidaVenta/?',
    POST_TASK_SAKE_EDIT_STATUS = '/api/tareasVenta/status/edit',
    POST_TASK_SALE_CREATE_BY_PRODUCTS = '/api/tareaVentas/crearMasivo/venta/?',


    //CONTRACT

    GET_CONTRACT_BY_ID = '/api/contratos/idTipoContrato/?',
    GET_CONTRACT_All = '/api/contratos',
    GET_CONTRACT_TYPES = '/api/contratos/tipos',
    GET_ACTIVE_CONTRACT = '/api/contratos/activo/idTipoContrato/?',
    GET_CONTRACT_BY_IDCONTRACT = '/api/contratos/idContrato/?',
    GET_CONTRACT_DELETED_BY_ID = '/api/contratos/eliminados/idTipoContrato/?',
    POST_CREATE_CONTRACT = '/api/contratos/crear',
    POST_EDIT_CONTRACT = '/api/contratos/editar',
    POST_DELETE_CONTRACT = '/api/contratos/eliminar',
    POST_ENABLE_CONTRACT = '/api/contratos/reactivar',
    POST_CONTRACT_IMAGE = '/api/contratos/subiImagen',
    POST_EDIT_ACTIVE = '/api/contratos/isActive/editar',

    //CONFIG
    GET_CONFIG = '/api/configuracion',
    POST_EDIT_CONFIG = '/api/configuracion/create/edit',

    //PROGRAMMED PAYMENT

    GET_ALL_PROGRAMMED_PAYMENT = '/api/pagosProgramados',
    GET_PROGRAMMED_PAYMENT_BY_MONTH_YEAR = '/api/pagosProgramados/mes/?/anio/?',
    POST_CREATE_PROGRAMMED_PAYMENT = '/api/pagoProgramado/crear',
    POST_EDIT_STATUS_PROGRAMMED_PAYMENT = '/api/pagoProgramado/editarStatus',
    GET_PROGRAMMED_PAYMENT_BY_ID = '/api/pagoProgramado/idPagoProgramado/?',
    GET_STATUS_PROGRAMMED_PAYMENT = '/api/statusPagoProgramado',

    //WAREHOUSE
    GET_ALL_WAREHOUSE = '/api/almacenes',
    GET_WAREHOUSE_BY_ID = '/api/almacenes/idAlmacen/?',
    GET_ALL_WAREHOUSE_DELETED = '/api/almacenes/eliminados',
    POST_EDIT_WAREHOUSE = '/api/almacenes/editar',
    POST_ENABLE_WAREHOUSE = '/api/almacenes/reactivar',
    POST_DELETE_WAREHOUSE = '/api/almacenes/eliminar',
    POST_CREATE_WAREHOUSE = '/api/almacenes/crear',
    EXCEL_WAREHOUSE_GENERAL = '/api/almacenes/generarExcel/idAlmacen/?',
    EXCEL_WAREHOUSE_PRODUCT = '/api/almacenes/producto/generarExcel/idAlmacen/?/idProducto/?',
    

    //EXCEL
    DOWNLOAD_EXCEL = '/excel/downloadExcel/name/?/downloadName/?/',

    //VARIABLESALE
    GET_VARIABLE_SALE_ALL = '/api/variableVenta',
    GET_VARIABLE_SALE_BY_ID_SALE = '/api/variableVenta/idVenta/?',
    GET_VARIABLE_SALE_BY_ID = '/api/variableVenta/idVariableVenta/?',
    POST_VARIABLE_SALE_CREATE = '/api/variableVenta/editar',
    POST_VARIABLE_SALE_EDIT = '/api/variableVenta/editar',
    GET_VARIABLE_SALE_ALL_DELETE = '/api/variableVenta/eliminados',
    POST_VARIABLE_SALE_DISABLE = '/api/variableVenta/eliminar',

    // By Business Line
    
    GET_VARIABLE_SALE_BY_BUSINESS_LINE_ALL = '/api/variablesPorLineaDeNegocio',

    //TABLECONTROL
    
    GET_TABLE_CONTROL_ALL = '/api/mesasControl',
    GET_TABLE_CONTROL_BY_ID_CLIENT = '/api/mesasControl/idCliente/?',
    GET_TABLE_CONTROL_BY_ID = '/api/mesasControl/idMesaControl/?',
    POST_TABLE_CONTROL_CREATE = '/api/mesaControl/editar',
    POST_TABLE_CONTROL_EDIT = '/api/mesaControl/editar',
    POST_TABLE_CONTROL_ENABLE = '/api/mesaControl/reactivar',
    POST_TABLE_CONTROL_DISABLE = '/api/mesaControl/eliminar',
    POST_TABLE_CONTROL_ACCEPT = '/api/mesaControl/aprobar',
    POST_TABLE_CONTROL_DISCARD = '/api/mesaControl/desaprobar',
    GET_TABLE_CONTROL_ALL_DELETE = '/api/mesasControl/eliminados',

    //TABLECONTROLRESPONSE

    GET_TABLE_CONTROL_RESPONSE_BY_ID_TABLE_CONTROL = '/api/respuestaMesasControl/idMesaControl/?',
    POST_TABLE_CONTROL_RESPONSE_DISABLE = '/api/respuestaMesaControl/eliminar',
    POST_TABLE_CONTROL_RESPONSE_EDIT = '/api/respuestaMesaControl/editar',
    POST_TABLE_CONTROL_RESPONSE_APROBE = '/api/respuestaMesaControl/aprobar',
    POST_TABLE_CONTROL_RESPONSE_CREATE = '/api/respuestaMesaControl/crear',

    //PREPARTY
    GET_CLONE_PARTY_TO_PREPARTY = "/api/ventas/clonarVenta/?/prePartyTickets/?/date/?",
    POST_CLONE_PARTY_EMPLOYEES = "/api/ventas/clonarVentaEmpleado",
    GET_LIST_SELL_EMPLOYEES = '/api/ventas/ventaEmpleado/idVenta/?',
    POST_EDIT_PARTY_EMPLOYEES = "/api/ventas/editarVentaEmpleado",

    //Employees
    GET_EMPLOYEES_ACTIVE = "/api/empleados",
    GET_EMPLOYEE = "/api/empleados/idEmpleado/?",
    GET_EMPLOYEES_DELETED = "/api/empleados/eliminados",
    POST_EMPLOYEES_DELETE_BY_ID = "/api/empleados/eliminar",
    POST_EMPLOYEES_RESTORE_BY_ID = "/api/empleados/recuperar",
    POST_EMPLOYEES_CREATE = "/api/empleados/crear",
    POST_EMPLOYEES_EDIT = "/api/empleados/editar",

    //Employees roles
    GET_ROL_EMPLOYEES_ACTIVE = "/api/empleados/rol",
    GET_ROL_EMPLOYEE = "/api/empleados/rol/idRolEmpleado/?",
    GET_ROL_EMPLOYEES_DELETED = "/api/empleados/rol/eliminados",
    POST_ROL_EMPLOYEES_DELETE_BY_ID = "/api/empleados/rol/eliminar",
    POST_ROL_EMPLOYEES_RESTORE_BY_ID = "/api/empleados/rol/recuperar",
    POST_ROL_EMPLOYEES_CREATE = "/api/empleados/rol/crear",
    POST_ROL_EMPLOYEES_EDIT = "/api/empleados/rol/editar",
}