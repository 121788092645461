import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable()
export class HTTPInterceptorService implements HttpInterceptor {
    constructor(
        private sessionService_userInfo:SessionService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token:string = this.sessionService_userInfo.fnGetSessionToken()
        if(token){
            req = req.clone({
                setHeaders: {
                    'sessionToken': token
                }
            });
        }else{
            token = this.sessionService_userInfo.fnGetSessionTokenRepresentante()
            if(token){
                req = req.clone({
                    setHeaders: {
                        'sessionTokenRepresentante': token
                    }
                });
            }else{
                token = this.sessionService_userInfo.fnGetSessionTokenAsistente()
                if(token){
                    req = req.clone({
                        setHeaders: {
                            'sessionTokenAsistente': token
                        }
                    });
                }
            }
        }   
        return next.handle(req);
    }
}