import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';
import { Observable, Subscription } from 'rxjs';
import { LOGIN_STATE_ENUM } from '../enums/login-state.enum';
import { LoginResponseModel } from '../models/login-response.model';


@Injectable()
export class PermissionsGuard implements CanActivate {

    constructor(
        private router: Router,
        private sessionService_userInfo: SessionService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve) => {
            let checked: boolean = false;
            let sub_sessionData: Subscription = this.sessionService_userInfo._permissions.subscribe((data:LoginResponseModel) => {
                if (!data || checked == true) {
                    return;
                }
                checked = true;
                let requiredPermissions:string[] = route.data.permissions;
                let hasAccess:boolean = true;
                requiredPermissions.forEach(permission=>{
                    if(data._usuario._tipoUsuario[permission] != true){
                        hasAccess = false;
                    }
                });
                if(!hasAccess){
                    this.router.navigate(['/system/workspace/home']);
                }
                resolve(hasAccess);
            })
            setTimeout(() => {
                if (sub_sessionData) {
                    sub_sessionData.unsubscribe();
                }
                if (checked == false) {
                    this.router.navigate(['/system/auth/login']);
                    resolve(false);
                }
            }, 10000);
        });
    }
}