import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl } from "@angular/forms";
import { DatePipe } from "@angular/common";

@Component({
    selector: "az-input-date",
    templateUrl: "./input-date.component.html",
    styleUrls: ["./input-date.component.scss"]
})

export class InputDateComponent implements OnChanges{
    //Input and output for the date
    _fecha:Date|string
    _formatDate:string
    @Input() 
    set fecha(val:Date|string){
        this._fecha = val
    }
    get fecha(){
        return this._fecha
    } 
    @Output() 
        fechaChange:EventEmitter<Date|string> = new EventEmitter<Date|string>()

    //Input for disabled form section
    @Input() disabled:boolean = false
    @Input() readOnly:boolean = false

    constructor(private datePipe:DatePipe){}

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.fecha){
            this.dateFormat();
        }
    }

    dateFormat(){
        //If modify this, needs to modify in input component
        this.fechaChange.emit(this._fecha);
        this._formatDate = this.datePipe.transform(this.fecha, "EE dd/MMM/yyyy", '', 'es')
        if(this._formatDate != null){
            this._formatDate = this._formatDate.toUpperCase();
            while(this._formatDate.includes('.')) this._formatDate = this._formatDate.replace('.','');
        }
    }
}