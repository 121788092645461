import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { SithecSuiteService } from '../../../../sithec-suite.service';
import { DatePipe, registerLocaleData } from "@angular/common";
import localeEs from '@angular/common/locales/es'
registerLocaleData(localeEs);

@Component({
  selector: 's2-form-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit, OnChanges {

  @Input() S2FormControl:FormControl;
  @Input() S2FormGroup:FormGroup;
  @Input() placeholder:string = 'placeholder';
  @Input() label:string = 'label';
  @Input() id:string = 'default-id';
  @Input() idForm:string = 'default-idForm';
  @Input() colum:string = '6';
  @Input() type:string;
  @Input() translate:any;

  @Input() sending:boolean;


  @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  _formatDate:string

  constructor(
    private sithecSuiteService_tools:SithecSuiteService,
    private datePipe:DatePipe
  ) { }

  ngOnInit() {
    this.sithecSuiteService_tools.fnSetComponmentsForm(this.idForm,this.id,this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.S2FormControl && changes.type!.currentValue! == 'date'){
      this.fnGetDate();
    }
  }

  fnKeyup(event):void{
    let emitEvent = {id:this.id,event:event};
    this.onKeyup.emit(emitEvent)
  }

  fnChange(event):void{
    let emitEvent = {id:this.id,event:event};
    this.onChange.emit(emitEvent)
    if(this.type == 'date'){
      this.fnGetDate();
    }
  }

  fnGetInputLabel(): Observable<string> {
    let $label: BehaviorSubject<string> = new BehaviorSubject<string>('Label');
    if (this.label) {
      if(this.translate){
        this.fnTranslate(this.label,$label);
      }else{
        $label.next(this.label);
      }
    }

    return $label.asObservable();
  }

  fnGetInputPlaceholder(): Observable<string> {
    let $placeholder: BehaviorSubject<string> = new BehaviorSubject<string>('Placeholder');
    if (this.placeholder) {
      if(this.translate){
        this.fnTranslate(this.placeholder,$placeholder);
      }else{
        $placeholder.next(this.placeholder);
      }
    }
    return $placeholder.asObservable();
  }

  fnTranslate(translate:string = 'no-data',$obs:BehaviorSubject<string>){
    this.translate.get(translate).subscribe(data=>{
      $obs.next(data);
    });
  }

  fnDisableField(){
    this.S2FormControl.disable();
  }

  fnEnableField(){
    this.S2FormControl.enable();
  }

  fnGetDate(){
    if(this.type == 'date'){
      //If modify this, needs to modify in input-date
      this._formatDate = this.datePipe.transform(this.S2FormControl.value, "EE dd/MMM/yyyy", '', 'es')
      if(this._formatDate!=null){
        this._formatDate = this._formatDate.toUpperCase();
        while(this._formatDate.includes('.')) this._formatDate = this._formatDate.replace('.','');
      }
    }
  }

  fnSetValue(value: any){
    this.S2FormControl.setValue(value);
    if(this.type == 'date'){
      this.fnGetDate()
    }
  }

}
