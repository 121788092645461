//import 'pace';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core'; 
import { routing } from './app.routing';
import { AppConfig } from './app.config';
import { AppComponent } from './app.component';
import { ErrorComponent } from './pages/error/error.component';
import { ToastrModule } from 'ngx-toastr';
import { ApiCallService } from './system/services/api-call.service';
import { SessionService } from './system/services/session.service';
import { AuthenticationService } from './system/services/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { AuthGuard } from './system/guards/auth.guard';
import { HTTPInterceptorService } from './system/services/http-interceptor.service';
import { SithecSuiteModule } from './system/components/sithec-suite/sithec-tools-suite.module';
import { FirebaseModule } from './system/pages/firebase/firebase.module';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PermissionsGuard } from './system/guards/permissions.guard';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormsModule} from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ContractsGuard } from './system/guards/contracts.guard';
import { SheetTutorialComponent } from './sheets/sheet-tutorial/sheet-tutorial.component';
import { AuthAttendeesGuard } from './system/guards/authAttendees.guard';
import { AuthAssistantsGuard } from './system/guards/authAssistants.guard';
import { DataDogInterceptor } from './system/services/errorInterceptor.service';
@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    SheetTutorialComponent
  ],
  imports: [
    BrowserModule,
    BsDropdownModule.forRoot(),
    DndModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    FirebaseModule,
    HttpClientModule,
    SithecSuiteModule,
    FormsModule,
    AgmCoreModule.forRoot({​​​​​​​​
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey:'AIzaSyDQniHUNuBi5YVA2_jLnb0Z9RhwUWvvgKQ',
      libraries: ['places']
          }​​​​​​​​),
    ToastrModule.forRoot(),
    SignaturePadModule,
    routing, CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),   
  ],
  providers: [
    AppConfig,
    CurrencyPipe,
    DatePipe,
    ApiCallService,
    SessionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataDogInterceptor,
      multi: true
    },
    {
			provide: HTTP_INTERCEPTORS,
			useClass: HTTPInterceptorService,
			multi: true
		},
    AuthGuard,
    AuthAttendeesGuard,
    AuthAssistantsGuard,
    ContractsGuard,
    PermissionsGuard,
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }