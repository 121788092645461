import { Component} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RepresentativeModel } from 'src/app/system/models/representative.model';
import { QuotationService } from "src/app/system/services/quotation.service";
import { RepresentativeService } from 'src/app/system/services/representative.service';

@Component({
  selector: 'az-sheet-tutorial',
  templateUrl: './sheet-tutorial.component.html',
  styleUrls: ['./sheet-tutorial.component.scss']
})
export class SheetTutorialComponent {
  isLoading: boolean = false;

  email: string;
  
  constructor(
    private feedbackService: ToastrService,
    private representativeService: RepresentativeService
    ) { 
    this.email = "";
    
  }


  enviar(){
    if(this.isLoading){
      this.feedbackService.warning("Cargando...");
      return;
    }
    if(this.esEmailValido()){
      this.isLoading = true;
      //se envian los datos
      this.representativeService
      .fnShareSheetFromWebForm({
        _correo: this.email
      } as RepresentativeModel)
      .then((success: string) => {
        this.feedbackService.success("Datos Enviados Correctamente");
        this.email = "";
      })
      .catch((error) => {
        this.feedbackService.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
    }else{
+     this.feedbackService.error("Correo Invalido");
    }
  }

  esEmailValido():boolean {
    let mailValido = false;
      'use strict';

      var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (this.email.match(EMAIL_REGEX)){
        mailValido = true;
      }
    return mailValido;
  }

}
