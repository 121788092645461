import { ResponseModel } from "src/app/system/models/reponse.model";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { Observable } from "rxjs/Observable";
import { LOGIN_STATE_ENUM } from "../enums/login-state.enum";
import { LoginResponseModel } from "../models/login-response.model";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { mergeMapTo } from "rxjs/operators";
import { ApiCallService } from "./api-call.service";
import { APIS_ENUM } from "../enums/apis.enum";
import { LoginModel } from "../models/login.model";

@Injectable()
export class SessionService {
  private $num_hasAccess: BehaviorSubject<number> = new BehaviorSubject<number>(
    LOGIN_STATE_ENUM.CHECKING
  );
  _num_hasAccess: Observable<number> = this.$num_hasAccess.asObservable();

  private $permissions: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _permissions: Observable<any> = this.$permissions.asObservable();

  tokenNotifications: string;

  constructor(
    private afMessaging: AngularFireMessaging,
    private apiCall_restfull: ApiCallService
  ) {}

  fnSaveSession(any_logindata: LoginResponseModel): void {
    this.$permissions.next(any_logindata);

    this.$num_hasAccess.next(LOGIN_STATE_ENUM.LOGGED);
    localStorage.setItem("sessionToken", any_logindata._token);
  }

  fnSaveSessionRepresentante(any_logindata: LoginResponseModel): void {
    this.$permissions.next(any_logindata);

    this.$num_hasAccess.next(LOGIN_STATE_ENUM.LOGGED);
    localStorage.setItem("sessionTokenRepresentante", any_logindata._token);
  }

  fnSaveSessionAsistente(any_logindata: LoginResponseModel): void {
    this.$permissions.next(any_logindata);

    this.$num_hasAccess.next(LOGIN_STATE_ENUM.LOGGED);
    localStorage.setItem("sessionTokenAsistente", any_logindata._token);
  }

  fnLogout(): void {
    this.$permissions.next(null);
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("sessionTokenRepresentante");
    localStorage.removeItem("sessionTokenAsistente");
    if (this.tokenNotifications) {
      this.afMessaging
        .deleteToken(this.tokenNotifications)
        .toPromise()
        .then((res) => {
          this.fnRequestNotificationPermissions();
        })
        .catch((rej) => {
          console.error(rej);
        });
    }
  }

  fnLogoutRepresentante(): void {
    this.$permissions.next(null);
    localStorage.removeItem("sessionTokenRepresentante");
    if (this.tokenNotifications) {
      this.afMessaging
        .deleteToken(this.tokenNotifications)
        .toPromise()
        .then((res) => {
          this.fnRequestNotificationPermissions();
        })
        .catch((rej) => {
          console.error(rej);
        });
    }
  }

  fnLogoutAsistente(): void {
    this.$permissions.next(null);
    localStorage.removeItem("sessionTokenAsistente");
    if (this.tokenNotifications) {
      this.afMessaging
        .deleteToken(this.tokenNotifications)
        .toPromise()
        .then((res) => {
          this.fnRequestNotificationPermissions();
        })
        .catch((rej) => {
          console.error(rej);
        });
    }
  }

  sub_notifications: Subscription;
  fnRequestNotificationPermissions() {
    if (this.sub_notifications) {
      this.sub_notifications.unsubscribe();
    }
    this.sub_notifications = this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe(
        (token) => {
          this.fnSetNotificationToken(token);
        },
        (error) => {
          console.log("Error token", error);
        }
      );
  }

  fnSetLoginStateValue(num_state: number): void {
    this.$num_hasAccess.next(num_state);
  }

  fnGetSessionToken(): string {
    let token = localStorage.getItem("sessionToken");
    if (token) {
      return token == undefined ? null : token;
    }
    return null;
  }

  fnGetSessionTokenRepresentante(): string {
    let token = localStorage.getItem("sessionTokenRepresentante");
    if (token) {
      return token == undefined ? null : token;
    }
    return null;
  }

  fnGetSessionTokenAsistente(): string {
    let token = localStorage.getItem("sessionTokenAsistente");
    if (token) {
      return token == undefined ? null : token;
    }
    return null;
  }

  /*para el push notification*/

  fnSetNotificationToken(token: string) {
    let checked: boolean = false;
    let sub_statusApp: Subscription;
    sub_statusApp = this._num_hasAccess.subscribe((status) => {
      if (checked) {
        return;
      }
      if (status == LOGIN_STATE_ENUM.CHECKING) {
        return;
      }
      if (status == LOGIN_STATE_ENUM.LOGGED) {
        this.tokenNotifications = token;
        this.fnSendTokenNotification();
        //NOTIFICAR TOKEN AL BACK
        checked = true;
      } else {
        checked = true;
        this.tokenNotifications = token;
      }
    });
    setTimeout(() => {
      if (sub_statusApp) {
        sub_statusApp.unsubscribe();
      }
      if (!checked) {
        this.tokenNotifications = null;
      }
    }, 4000);
  }

  fnGetNotificationToken() {
    return this.tokenNotifications == null ? "" : this.tokenNotifications;
  }

  fnSendTokenNotification() {
    let loginModel_data: LoginModel = {
      _tokenNotificacion: this.tokenNotifications,
    } as any;

    return new Promise((resolve, reject) => {
      this.apiCall_restfull
        .fnPostPromise(loginModel_data, APIS_ENUM.POST_SEND_TOKEN_NOTIFICATION)
        .then((res) => {})
        .catch((rej) => {});
    });
  }
}
