// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//var ip = 'https://azul.festary.com';
export const environment = {
  production: false,


  // para local----------------------------------
  
  //ip: 'http://localhost:63232',
  //testIp: 'http://localhost:4200',
  //images: 'http://localhost:63232',

  //para desarrollo------------------------------
  
   ip: 'https://festary.sithec.com.mx',
   images: 'https://festary.sithec.com.mx',

  //para produccion------------------------------
  
  
  // ip: 'https://sistema.festary.com',
  // images: 'https://sistema.festary.com',

  firebase: {
    apiKey: "AIzaSyBvZX_LR1HNlxGExGzrGXzuBz9dpC6WgQU",
    authDomain: "festary-1bc5c.firebaseapp.com",
    databaseURL: "https://festary-1bc5c.firebaseio.com",
    projectId: "festary-1bc5c",
    storageBucket: "festary-1bc5c.appspot.com",
    messagingSenderId: "282707128540",
    appId: "1:282707128540:web:de8ee98a81f67e9f96c419",
    measurementId: "G-DSBM8TQYK3"
  },
  datadogEnv: 'development',
  isFestary: true
  /*ip: ip,
  images: ip,
  firebase: {
    apiKey: "AIzaSyAJ7R3jTS_1QAWg5NafjysGqs7YH6yOBrs",
    authDomain: "eventlink-azul.firebaseapp.com",
    projectId: "eventlink-azul",
    storageBucket: "eventlink-azul.appspot.com",
    messagingSenderId: "186720660300",
    appId: "1:186720660300:web:352154a784a30a047f7de9",
    measurementId: "G-RDD135JVXS"
  },
  datadogEnv: 'azultest'*/

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
