import { ResponseModel } from 'src/app/system/models/reponse.model';
import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { APIS_ENUM } from '../enums/apis.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigModel } from '../models/config.model';
// import { reject } from 'core-js/fn/promise';

@Injectable({
  providedIn: 'root'
})
export class ConfigserviceService {

  protected $obj: BehaviorSubject<ConfigModel> = new BehaviorSubject<ConfigModel>(null);
  _obj: Observable<ConfigModel> = this.$obj.asObservable();

  imagenesActivas: boolean = false;
  urlwhite = "";
  urlblack = "";
  constructor(private apiCallService_restfull: ApiCallService) { 

  }

  FnRecuperar(){
    return new Promise((resolve,reject)=>{
     this.apiCallService_restfull.fnGetPromise([], APIS_ENUM.GET_CONFIG)
      .then((data)=> {
        this.imagenesActivas = data._activarImagenes;
        this.urlblack = data._urlImagen2;
        this.urlwhite = data._urlImagen;
        this.$obj.next(data);
        resolve(data)
      }).catch((data)=>{
        reject('error')
      })
    })
     
  }

  FnEditar(obj){
    return new Promise((resolve,reject)=>{
     this.apiCallService_restfull.fnPostPromise(obj, APIS_ENUM.POST_EDIT_CONFIG)
      .then((data: ResponseModel) => {
        if(data._success){
          resolve(data)
        }else{
          reject(data._message)
        }
      })
      .catch(err=>{
        reject('Error de conexion')
      })

    })
     
  }

  fnSubirImagen(formData,id){
    return new Promise((resolve,reject)=>{
      this.apiCallService_restfull.fnPostWithParamsPromise(formData,[id],'/api/configuracion/image/edit/?')
      // revisar el tipo de data
      .then((data: ResponseModel) => {
        if(data._success){
          resolve(data)
        }else{
          reject(data._message)
        }

      })
    .catch(err=>{
      reject('Error de conexión');
    })
  })

  }

  fnSubirImagen2(formData,id){
    return new Promise((resolve,reject)=>{
      this.apiCallService_restfull.fnPostWithParamsPromise(formData,[id],'/api/configuracion/image2/edit/?')
      // revisar el tipo de data
      .then((data: ResponseModel) => {
        if(data._success){
          resolve(data)
        }else{
          reject(data._message)
        }

      })
    .catch(err=>{
      reject('Error de conexión');
    })
  })

  }
}
