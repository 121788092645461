import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SessionService } from '../services/session.service';
import { Observable } from 'rxjs';
import { LOGIN_STATE_ENUM } from '../enums/login-state.enum';


@Injectable()
export class AuthAssistantsGuard implements CanActivate {

    constructor(
        private router: Router,
        private sessionService_userInfo: SessionService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        console.log("Cheking")
        if(!this.sessionService_userInfo.fnGetSessionTokenAsistente()){
            console.log("Logout")
            this.sessionService_userInfo.fnLogout();
            this.sessionService_userInfo.fnLogoutAsistente();
            this.sessionService_userInfo.fnSetLoginStateValue(LOGIN_STATE_ENUM.NO_LOGGED);
            this.router.navigate(['system/auth/login-assistants'])
            return false;
        }
        return true;
    }
}