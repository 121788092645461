import { Component, ViewEncapsulation, NgZone } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { mergeMapTo } from "rxjs/operators";
import { SessionService } from "./system/services/session.service";
import { database } from "firebase";
import { NotificationsService } from "./system/services/notifications.service";
import { NotificationModel } from "./system/models/notification.model";
import { LOGIN_STATE_ENUM } from "./system/enums/login-state.enum";
import { AuthenticationService } from "./system/services/authentication.service";
import { ConfigserviceService } from "./system/services/configservice.service";
import { ConfigModel } from "./system/models/config.model";
import { DataDogService } from "./system/services/datadog.service";

@Component({
  selector: "az-root",
  encapsulation: ViewEncapsulation.None,
  template: `<router-outlet></router-outlet>`,
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  //firebase push notifications
  constructor(
    private afMessaging: AngularFireMessaging,
    private authService: AuthenticationService,
    private sessionService: SessionService,
    private notificationService: NotificationsService,
    private configurationService: ConfigserviceService,
    private dataDogService: DataDogService,
    private zone: NgZone
  ) {
    this.fnGetTokenNotification();
    this.fnRecoverSession();
    this.fnCheckStatusImages();
    this.fnInitDataDog();
  }

  fnInitDataDog(){
    this.dataDogService.initScriptDataDog();
  }

  fnCheckStatusImages() {
    this.configurationService
      .FnRecuperar()
      .then((res: ConfigModel) => {
        if (res._idConfiguracion != 0) {
          this.configurationService.urlwhite = res._urlImagen;
          this.configurationService.urlblack = res._urlImagen2;
          if (res._activarImagenes == true) {
            this.configurationService.imagenesActivas = true;
          }
        }
      })
      .catch(() => {});
  }

  fnRecoverSession(): void {
    let token = this.sessionService.fnGetSessionToken();
    if (token) {
      this.authService
        .fnTokenLoginUser(token)
        .then(() => {})
        .catch((error) => {});
    } else {
      token = this.sessionService.fnGetSessionTokenRepresentante();
      if(token){
        this.authService
        .fnTokenLoginRepresentante(token)
        .then(() => {})
        .catch((error) => {});
      }else{
        token = this.sessionService.fnGetSessionTokenAsistente();
        if(token){
          this.authService
          .fnTokenLoginAsistente(token)
          .then(() => {})
          .catch((error) => {});
        }else{
          this.sessionService.fnLogout();
          this.sessionService.fnSetLoginStateValue(LOGIN_STATE_ENUM.LOGOUT);
        }
      }
    }
  }

  fnGetTokenNotification() {
    //Este se ejecuta cada vez que cambiamos de pestaña
    this.sessionService.fnRequestNotificationPermissions();

    this.afMessaging.messaging.subscribe((data) => {
      data.onTokenRefresh((token) => {});
      data.usePublicVapidKey(
        "BOmdyMoUbrz6DU7UjN1tx0gZP-BuguPZ9WOU9DN94O2JN1KouUfE7gRuQpyHUPybc4flUcs-HVkeNLazOFsoZkQ"
      );

      data.onMessage((message) => {
        this.zone.run(() => {
          this.notificationService.fnGetLastTracing();
        });
      });
    });
  }
}
